import "./StarField.css";

// import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

function StarField() {
    return (
        <div>
            <div className="body-center">
                <h1>You've reached the end of space.</h1>
                <div className="social-container">
                    <a
                        href="https://www.instagram.com/arvind.rukmaji/"
                        target="_blank"
                    >
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                        href="https://twitter.com/arvind_rukmaji"
                        target="_blank"
                    >
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <p className="copyright">&copy; The Space Jockey</p>
                </div>
            </div>
            <div id="space">
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
            </div>
        </div>
    );
}

export default StarField;
